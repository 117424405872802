export default {
  firebaseCredential: {
    type: "service_account",
    project_id: "coinscopeassets",
    private_key_id: process.env.FIREBASE_PRIVATE_KEY_ID,
    private_key:
      "-----BEGIN PRIVATE KEY-----\nMIIEuwIBADANBgkqhkiG9w0BAQEFAASCBKUwggShAgEAAoIBAQCjCqGCDXAY0DZn\nASEDu1EcsXXY8mlh65X1qSqqGzDGoXlqBKs9lv7HH+vYdpIrV9nVBCzQf5uCLAx+\n4w7OpaRDT6dxZzRem117Rv1kU69kxt5ku3On3ZHe9qaymr6xbn6/qbVD6CWlVrCT\n0J1YmqmKlZUaK/F7pUuKuKnWBGiDYuQe/k9Z2jsRA+PTMQas9IoHyr3XieMz3dZ3\nxXv2Zt+CxbR/gmK2Ykk1zIaEC1GazFYzSaBTKlPrEwUSzpWT6yMFroGssBmVZ+zJ\n8Bt6qG2IkLunWDBq9IJtwKm5YMU/I0c/4vuKp/k6cAdN89QRuHQ5V/hdUS8RHI23\nlfUAygDZAgMBAAECgf9tXzzW8HEPIyZsnYh3GobGlquq3VmyNFe6DgXEFyQYxheb\nseyDID9BENOMHiCarpiDgK/kx9fOagKAcYywivYu/i8zOTukGWIT+2kzoAD33gof\nIo+EcvxW2jBw0xnD7SiRSDYu8UgtofnjUP20DC7AyMq0f5LUcFKBANdUD+U/tI0n\nlROrcIpHlmvlJ16zVSpR3votW/IgDBPWqNUZbYoVu9YubD/BD9iByeV2u+FngTiU\n0nhN4g+ajA9Q4E7szShJKtEIo4D6TECrFmfgWokyELs/lUk4HnBhoHHODLG5u52D\nCu93xoyX6Yp6hUnX2T41srEt2qNtktXJoNyvMMECgYEA2Jj7grNc+Fn3Lqt1DQ5o\nnQ1zmLaNzGl0GDWiHr60Hqu4uByl0gtZKI9jii2uV5fSXOnpizBoUoF+Sw2K+BMq\nbDx/KXYMnVOCQxSmPljgM1ny9n3czRBjjOBbjNxYB11TZqHclmXj4pvRVXK5pw7u\nu7ZFlW4cNTTMiKb1j/3yWe8CgYEAwLOGoNiZ92gmsY274MsDxyK7xUF6Hetq1RZO\nfPsWLH99AOKsa6JS3envvQ3e8qZ6CQ8TL64oOAp2npjv5vEvf4AnijMT+dkMxeH/\nt1KJzIsi2REpf5LoIIiG3OpoyvAVCLMbMbMCq+7TkBTGctQseWs1nQ4xq6BeiMzB\naelSubcCgYEAoIP/IEU8SPCjNnrqK9rZvEoTuMDRJQOhfa+BjbdWVMR+CDNyz86J\nLH3XDrf70/43b5QJ95TQOxSsO4K10lKhxcSvmFJb1tvkVUIgUWQcAvJOFE4CLBZR\nMD20mxdQRWTsQcsCZpLr5ywCXoi+/Qcb9RCHUM5hKS0PxtW0dhmEWRECgYB/fwhn\nKYiNIfLf7PfOnmPMgJWdyFmkrBsOC2k41ePw4xTw3gjYWhjbzKka+69z+mOp1LiM\nBn2DvqCHBIdN1Gvpvv/qNytTVqFV19aaE1kq5S/UUL+VMby1sb8RMWLOSpSQIfiv\nJFJzMVv261f1p54ZY66gUEDa3gtOFbMugPqD9wKBgAQTNRQeaCsgtW4aIrt+zQbr\namZWdHHp5g/+UIMp/IzJmNMqdT2lwc51yudl9W7QLgxtfktDYVD9zwvgwbGksV+s\nm3iElOzf9EZH042c8ZtS5RNPErDPOZFR52Sapmk1UQxrdGWZL5DKE7DsI/sYKVr6\nf+VfWhQGY5Fs0u+MoCDH\n-----END PRIVATE KEY-----\n",
    client_email: "backend@coinscopeassets.iam.gserviceaccount.com",
    client_id: "115355316241125944203",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_x509_cert_url:
      "https://www.googleapis.com/robot/v1/metadata/x509/backend%40coinscopeassets.iam.gserviceaccount.com",
  },
  promotionTypes: ["Promotion List", "Banner Ads", "Wide Banner", "Popup"],
  promotionDateTypes: ["Promotion List", "Banner Ads", "Wide Banner", "Popup"],
  priceByType: {
    "Promotion List": 0.2,
    "Banner Ads": 0.3,
    "Wide Banner": 0.7,
    Popup: 1,
    AMA: 1.5,
  },
  coinscopeHoldingDiscount: "300,000",
  registeredUsers: 2616112,
  registeredUsersFormatted: "2,6 Million",
  dailyUsers: 50000,
  dailyUsersFormatted: "50,000",
  totalAudits: 616,
  totalAirdrops: 160,
  telegramUsers: 14000,
  newsletterUsers: 19000,

  bannerWidth: 320,
  bannerHeight: 128,
  wideBannerWidth: 1200,
  wideBannerHeight: 90,

  buybackFee: 10,
  minTokensToHoldAirdrop: 50000,
};
